import { DashboardConfig } from "@/types";

export const dashboardConfig: DashboardConfig = {
  mainNav: [],
  sidebarNav: [
    {
      title: "Settings",
      href: "/settings",
      icon: "settings",
    },
  ],
};
